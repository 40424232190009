import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import {
  addTime,
  daysBetween,
  getDefaultTimeZoneOnBrowser,
  getExclusiveEndDate,
  getStartOf,
  parseDateFromISO8601,
  subtractTime,
} from '../../util/dates';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 10;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/SearchClaim/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/SearchClaim/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/SearchClaim/FETCH_LISTINGS_ERROR';

export const FETCH_LISTINGS_CAROUSEL_REQUEST = 'app/SearchClaim/FETCH_LISTINGS_CAROUSEL_REQUEST';
export const FETCH_LISTINGS_CAROUSEL_SUCCESS = 'app/SearchClaim/FETCH_LISTINGS_CAROUSEL_SUCCESS';
export const FETCH_LISTINGS_CAROUSEL_ERROR = 'app/SearchClaim/FETCH_LISTINGS_CAROUSEL_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  querySearchClaimError: null,
  currentPageResultIds: [],
  queryListingsCarouselInProgress: false,
  queryListingsCarouselError: null,
  listingsCarouselPagination: null,
  listingsCarouselParams: null,
  listingsCarousel: [],
};

const resultIds = data => data.data.map(l => l.id);

const sectionSearchClaimReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        querySearchClaimError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryInProgress: false,
        querySearchClaimError: payload,
      };

    case FETCH_LISTINGS_CAROUSEL_REQUEST:
      return {
        ...state,
        listingsCarouselParams: payload.queryParams,
        queryListingsCarouselInProgress: true,
        queryListingsCarouselError: null,
      };
    case FETCH_LISTINGS_CAROUSEL_SUCCESS:
      return {
        ...state,
        listingsCarousel: denormalisedResponseEntities(payload),
        listingsCarouselPagination: payload.data.meta,
        queryListingsCarouselInProgress: false,
      };
    case FETCH_LISTINGS_CAROUSEL_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        queryListingsCarouselInProgress: false,
        queryListingsCarouselError: payload,
      };

    default:
      return state;
  }
};

export default sectionSearchClaimReducer;

// ================ Action creators ================ //

export const querySearchClaimRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const querySearchClaimSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const querySearchClaimError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryListingsCarouselRequest = queryParams => ({
  type: FETCH_LISTINGS_CAROUSEL_REQUEST,
  payload: { queryParams },
});

export const queryListingsCarouselSuccess = response => ({
  type: FETCH_LISTINGS_CAROUSEL_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsCarouselError = e => ({
  type: FETCH_LISTINGS_CAROUSEL_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

// Throwing error for new (loadData may need that info)
export const querySearchClaimListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(querySearchClaimRequest(queryParams));
  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(querySearchClaimSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(querySearchClaimError(storableError(e)));
    });
};

export const queryListingsCarousel = (queryParams, config) => (dispatch, getState, sdk) => {
  dispatch(queryListingsCarouselRequest(queryParams));

  const datesSearchParams = datesParam => {
    const currentTimezone =
      queryParams.timezone || typeof window !== 'undefined'
        ? getDefaultTimeZoneOnBrowser()
        : 'Etc/UTC';
    const searchTZ = currentTimezone || 'Etc/UTC';
    const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
    const values = datesParam ? datesParam?.split(',') : [];
    const hasValues = datesFilter && datesParam && values.length === 2;
    const { dateRangeMode, availability } = datesFilter || {};
    const isNightlyMode = dateRangeMode === 'night';
    const isEntireRangeAvailable = availability === 'time-full';

    // SearchPage need to use a single time zone but listings can have different time zones
    // We need to expand/prolong the time window (start & end) to cover other time zones too.
    //
    // NOTE: you might want to consider changing UI so that
    //   1) location is always asked first before date range
    //   2) use some 3rd party service to convert location to time zone (IANA tz name)
    //   3) Make exact dates filtering against that specific time zone
    //   This setup would be better for dates filter,
    //   but it enforces a UX where location is always asked first and therefore configurability
    const getProlongedStart = date =>
      searchTZ === 'Etc/UTC' ? subtractTime(date, 14, 'hours', searchTZ) : date;
    const getProlongedEnd = date =>
      searchTZ === 'Etc/UTC' ? addTime(date, 12, 'hours', searchTZ) : date;

    const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
    const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
    const endDate =
      hasValues && isNightlyMode
        ? endRaw
        : hasValues
        ? getExclusiveEndDate(endRaw, searchTZ)
        : null;

    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
    const day = 1440;
    const hour = 60;
    // When entire range is required to be available, we count minutes of included date range,
    // but there's a need to subtract one hour due to possibility of daylight saving time.
    // If partial range is needed, then we just make sure that the shortest time unit supported
    // is available within the range.
    // You might want to customize this to match with your time units (e.g. day: 1440 - 60)
    const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
    return hasValidDates
      ? {
          start: getProlongedStart(startDate),
          end: getProlongedEnd(endDate),
          // Availability can be time-full or time-partial.
          // However, due to prolonged time window, we need to use time-partial.
          availability: 'time-partial',
          // minDuration uses minutes
          minDuration,
        }
      : {};
  };
  const { dates } = queryParams;
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe = { sort: 'pub_rating' };

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage, ...datesMaybe, ...sortMaybe };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(queryListingsCarouselSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryListingsCarouselError(storableError(e)));
    });
};

export const loadListingCarousel = (params, search, config) => {
  const queryParams = { ...search };
  const page = params.page || 1;

  const { filterId, listingTypes = [] } = params;

  let searchParams = [];
  const categoryGroupTypeParams = { [`pub_categoryLevel1`]: filterId };
  const listingTypeParams =
    listingTypes && listingTypes.length > 0
      ? {
          [`pub_listingType`]: [...listingTypes],
        }
      : [];

  searchParams = { ...categoryGroupTypeParams, ...listingTypeParams };

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  return queryListingsCarousel(
    {
      ...searchParams,
      ...queryParams,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    },
    config
  );
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = params.page || queryParams.page || 1;

  const { type, cluster, preferred, filterId, key } = params;

  const isClusterOrPreferred = ['cluster', 'preferred'].includes(type);
  const isCategoryType = type === 'category';
  const isSearchType = type === 'search';
  const isCategoryGroupType = type === 'categoryGroup';

  let claimParams = [];
  if (isSearchType) {
    const filterParams = Array.isArray(filterId)
      ? { [`pub_${key}`]: `has_all:${filterId.join(',')}` }
      : { [`pub_${key}`]: `has_all:${filterId}` };
    claimParams = filterParams;
  } else if (isClusterOrPreferred) {
    const clusterOrPreferredIds = cluster?.length ? cluster : preferred?.length ? preferred : null;
    claimParams = { ids: clusterOrPreferredIds || [] };
  } else if (isCategoryType) {
    const categoryTypeParams = Array.isArray(filterId)
      ? { [`pub_listingType`]: `has_any:${filterId.join(',')}` }
      : { [`pub_listingType`]: filterId };
    claimParams = categoryTypeParams;
  } else if (isCategoryGroupType) {
    const categoryGroupTypeParams = Array.isArray(filterId)
      ? { [`pub_categoryLevel1`]: `has_any:${filterId.join(',')}` }
      : { [`pub_categoryLevel1`]: filterId };
    claimParams = categoryGroupTypeParams;
  }

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return querySearchClaimListings({
    ...claimParams,
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['author', 'images'],
    'fields.image': [
      'variants.scaled-small',
      'variants.scaled-medium',
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });
};
