import { SOURCE_RESULTS } from './configCustomCluster';
import { CREDITS_ORDER_TEMPLATE, CREDITS_TEMPLATE } from './configCustomCredits';
import { SOURCE_TYPEFORM_ID } from './configCustomTypeform';

// User types
export const USER_TYPE_MINDER = 'minder';
export const USER_TYPE_PARENT = 'user_parent';
export const USER_TYPE_EMPLOYER = 'user_employer';
export const USER_TYPE_HOTEL = 'user_hotel';

//user roles for sign up
export const ROLES = [
  {
    value: USER_TYPE_PARENT,
    name: 'Parent',
  },
  {
    value: USER_TYPE_MINDER,
    name: 'Minder',
  },
  /*  {
    value: USER_TYPE_EMPLOYER,
    name: 'Employer',
  }, */
];

// Array of user types to be considered as wall users
export const WALL_USERS = [
  USER_TYPE_PARENT,
  /*  USER_TYPE_EMPLOYER, */
  /*  USER_TYPE_MINDER, 'all_users', */
];

/* Hear us from */
export const FOUND_US_FROM = [
  {
    name: 'öogo Ambassador',
    placeholder: 'Enter Ambassador name',
    error: 'Please enter the name of the Ambassador',
  },
  {
    name: 'Facebook Group',
    placeholder: 'Enter group name and contact source',
    error: 'Please enter group name and contact source',
  },
  {
    name: 'Whatsapp Group',
    placeholder: 'Enter group name and contact source',
    error: 'Please enter group name and contact source',
  },

  {
    name: 'Referral',
    placeholder: 'Enter referral source',
    error: 'Please enter a referral source',
  },
  {
    name: 'My Organization',
    placeholder: 'Enter organization name',
    error: 'Please enter an organization name',
  },
  {
    name: 'My Educational Institution',
    placeholder: 'Enter institution name',
    error: 'Please enter an institution name',
  },
  { name: 'Search Engine' },
  { name: 'LinkedIn' },
  { name: 'TikTok' },
  { name: 'Instagram' },
  { name: 'X (Twitter)' },
  { name: 'Other', placeholder: 'Please specify', error: 'Please specify' },
];

//templates to be added to new corporate user
const corporateParams = {
  SOURCE_RESULTS,
  CREDITS_ORDER_TEMPLATE,
};

const corporateConfigs = {
  [process.env.REACT_APP_CORPORATE_SIGNUP_1_NAME]: {
    key: process.env.REACT_APP_CORPORATE_SIGNUP_1_KEY,
    isCorporate: true,
    airtableAccess: false,
    userRole: USER_TYPE_PARENT,
    hasDiscoverySource: false,
    typeformSourceId: process.env.REACT_APP_CORPORATE_SIGNUP_1_SOURCE,
    logo: require('../components/CorporateLogo/LogoImages/corp1.png'),
  },
  [process.env.REACT_APP_CORPORATE_SIGNUP_2_NAME]: {
    key: process.env.REACT_APP_CORPORATE_SIGNUP_2_KEY,
    isCorporate: true,
    airtableAccess: false,
    userRole: USER_TYPE_PARENT,
    hasDiscoverySource: false,
    typeformSourceId: SOURCE_TYPEFORM_ID,
    logo: require('../components/CorporateLogo/LogoImages/corp2.png'),
  },
  [process.env.REACT_APP_CORPORATE_SIGNUP_3_NAME]: {
    key: process.env.REACT_APP_CORPORATE_SIGNUP_3_KEY,
    isCorporate: true,
    airtableAccess: false,
    userRole: USER_TYPE_PARENT,
    hasDiscoverySource: false,
    typeformSourceId: SOURCE_TYPEFORM_ID,
    logo: require('../components/CorporateLogo/LogoImages/corp3.png'),
    logoStyle: { maxHeight: '46px' },
  },
  [process.env.REACT_APP_CORPORATE_SIGNUP_4_NAME]: {
    key: process.env.REACT_APP_CORPORATE_SIGNUP_4_KEY,
    isCorporate: true,
    airtableAccess: false,
    userRole: USER_TYPE_PARENT,
    hasDiscoverySource: false,
    typeformSourceId: SOURCE_TYPEFORM_ID,
    logo: require('../components/CorporateLogo/LogoImages/corp4.png'),
    logoStyle: { maxHeight: '46x', paddingBottom: '4px' },

    /*  logoStyle: { maxHeight: '48px', paddingTop: '8px' }, */
    /*     logoStyle: { maxHeight: ' 55px', position: 'absolute', left: '115px', top: '11px' }, */
  },
  [process.env.REACT_APP_CORPORATE_SIGNUP_5_NAME]: {
    key: process.env.REACT_APP_CORPORATE_SIGNUP_5_KEY,
    isCorporate: true,
    airtableAccess: false,
    userRole: USER_TYPE_PARENT,
    hasDiscoverySource: false,
    typeformSourceId: SOURCE_TYPEFORM_ID,
    logo: require('../components/CorporateLogo/LogoImages/corp5.png'),
    logoStyle: { maxHeight: '29px' },
  },
  [process.env.REACT_APP_CORPORATE_SIGNUP_6_NAME]: {
    key: process.env.REACT_APP_CORPORATE_SIGNUP_6_KEY,
    isCorporate: true,
    airtableAccess: false,
    userRole: USER_TYPE_PARENT,
    hasDiscoverySource: false,
    typeformSourceId: SOURCE_TYPEFORM_ID,
    logo: require('../components/CorporateLogo/LogoImages/corp6.png'),
    logoStyle: { maxHeight: '50px', marginTop: '5px' },
  },
};

export const resolveCompanyConfig = companyId => {
  return corporateConfigs[companyId] || null;
};

// setting variables for invite only Users
export const userGroupList = userGroup => {
  const corporateConfig = resolveCompanyConfig(userGroup);

  if (corporateConfig) {
    const {
      key,
      isCorporate,
      airtableAccess,
      userRole,
      hasDiscoverySource,
      typeformSourceId,
    } = corporateConfig;
    const creditsTemplate = CREDITS_TEMPLATE(typeformSourceId);

    return {
      key,
      isCorporate,
      airtableAccess,
      userRole,
      hasDiscoverySource,
      creditsTemplate,
      typeformSourceId,
      hasSearchCategory: false,
      ...corporateParams,
    };
  }

  switch (userGroup) {
    case 'parent': {
      const creditsTemplate = CREDITS_TEMPLATE(SOURCE_TYPEFORM_ID);
      return {
        key: process.env.REACT_APP_SIGNUP_PARENTS_KEY,
        isCorporate: false,
        airtableAccess: false,
        userRole: USER_TYPE_PARENT,
        hasDiscoverySource: false,
        hasSearchCategory: false,
        creditsTemplate,
        ...corporateParams,
      };
    }
    case 'oogoEvent': {
      const creditsTemplate = CREDITS_TEMPLATE(SOURCE_TYPEFORM_ID);
      return {
        key: process.env.REACT_APP_SIGNUP_PARENTS_KEY,
        isCorporate: false,
        airtableAccess: false,
        userRole: USER_TYPE_PARENT,
        hasDiscoverySource: false,
        hasSearchCategory: false,
        signupOrigin: 'oogoEvent',
        creditsTemplate,
        ...corporateParams,
      };
    }
    case 'oogoHotel': {
      const creditsTemplate = CREDITS_TEMPLATE(SOURCE_TYPEFORM_ID);
      return {
        key: process.env.REACT_APP_SIGNUP_PARENTS_KEY,
        isCorporate: false,
        airtableAccess: false,
        userRole: USER_TYPE_PARENT,
        hasDiscoverySource: false,
        creditsTemplate,
        hasSearchCategory: false,
        dashboardTabs: ['overview', 'book'],
        ...corporateParams,
      };
    }
    case 'parentPregnancyBirth': {
      return {
        key: process.env.REACT_APP_SIGNUP_PARENTS_KEY,
        isCorporate: false,
        airtableAccess: false,
        userRole: USER_TYPE_PARENT,
        hasDiscoverySource: false,
        dashboardTabs: ['overview', 'book'],
        noDashboard: true,
      };
    }
    case 'minder': {
      return {
        key: process.env.REACT_APP_SIGNUP_MINDER_KEY,
        isCorporate: false,
        airtableAccess: false,
        userRole: USER_TYPE_MINDER,
        hasDiscoverySource: true,
        showDashboard: false,
      };
    }
    case 'ambassador-tc': {
      return {
        key: `at${process.env.REACT_APP_SIGNUP_MINDER_KEY}ca`,
        isCorporate: false,
        airtableAccess: false,
        userRole: USER_TYPE_MINDER,
        hasDiscoverySource: true,
        ambassadorName: 'Tilly C',
      };
    }

    default: {
      return { key: null, airtableAccess: false, userRole: 'invalid' };
    }
  }
};

export const getTypeformIds = userGroup => {
  const corporateConfig = resolveCompanyConfig(userGroup);
  if (corporateConfig) {
    const { typeformSourceId } = corporateConfig;
    return {
      sourceTypeformId: typeformSourceId,
    };
  }

  switch (userGroup) {
    case 'demo-day-parent': {
      return {
        sourceTypeformId: SOURCE_TYPEFORM_ID,
      };
    }
    case 'parent': {
      return {
        sourceTypeformId: SOURCE_TYPEFORM_ID,
      };
    }
    case 'minder': {
      return {
        sourceTypeformId: SOURCE_TYPEFORM_ID,
      };
    }
    case 'parentPregnancyBirth': {
      return {
        sourceTypeformId: SOURCE_TYPEFORM_ID,
      };
    }
    default: {
      return { sourceTypeformId: SOURCE_TYPEFORM_ID };
    }
  }
};

// function to set default values to parentCorporateLink
export const getInviteOnlySignupLinkValues = ({ params, isSignup }) => {
  const { categoryType = null, listingLink = null } = params?.listingInfo || {};

  const categoryTypeMap = {
    group_pregnancy_birth: 'parentPregnancyBirth',
    oogo_hotel: 'oogoHotel',
    oogoEvent: 'oogoEvent',
    postCard: 'parent',
  };
  if (categoryTypeMap[categoryType]) {
    params.userGroup = categoryTypeMap[categoryType];
    params.key = categoryTypeMap[categoryType];
    params.isValid = true;
  }

  const hasUserGroup = !!params.userGroup;
  const hasKey = !!params.key;
  const signupAsMinder = !hasUserGroup && !hasKey && !categoryType;

  if (!isSignup || (isSignup && signupAsMinder)) {
    // Return default values
    return {
      isValidLinkKey: false,
      userRole: 'minder',
      hasAirtableAccess: false,
    };
  }

  const userGroup = typeof params.userGroup === 'string' ? params.userGroup : null;
  const linkKey = typeof params.key === 'string' ? params.key : null;
  const userGroupDetails = userGroupList(userGroup);
  const isValidLinkKey =
    (linkKey && userGroupDetails.key === linkKey && !!userGroupDetails.userRole) ||
    !!params.isValid;
  if (!isValidLinkKey) {
    return {
      isValidLinkKey: false,
      userRole: 'user_parent',
      hasAirtableAccess: false,
    };
  }
  const hasAirtableAccess = linkKey && userGroupDetails.airtableAccess;
  const userRole = userGroupDetails.userRole;
  const isCorporate = userGroupDetails.isCorporate;
  const isParent = userRole === USER_TYPE_PARENT;
  const hasCluster = isCorporate || isParent;
  const creditsTemplate = hasCluster && userGroupDetails.creditsTemplate;
  const creditsOrderTemplate = hasCluster && userGroupDetails.CREDITS_ORDER_TEMPLATE;
  const sourceResults = userGroupDetails.SOURCE_RESULTS;
  const hasDiscoverySource = userGroupDetails.hasDiscoverySource;
  const ambassadorName = userGroupDetails.ambassadorName || null;
  const corporateName = isCorporate ? userGroup : null;
  const noDashboard = userGroupDetails.noDashboard || null;
  const signupFrom = listingLink || null;
  const signupOrigin = categoryType || userGroupDetails.signupOrigin || null;
  const hasSearchCategory = userGroupDetails.hasSearchCategory || false;
  const dashboardTabs = userGroupDetails.dashboardTabs || null;
  const showDashboard =
    typeof userGroupDetails.showDashboard !== 'undefined' ? userGroupDetails.showDashboard : null;

  return {
    isValidLinkKey,
    corporateName,
    hasAirtableAccess,
    creditsTemplate,
    creditsOrderTemplate,
    sourceResults,
    userRole,
    isCorporate,
    hasDiscoverySource,
    ambassadorName,
    noDashboard,
    signupFrom,
    signupOrigin,
    hasSearchCategory,
    dashboardTabs,
    showDashboard,
  };
};
